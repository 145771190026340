import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "flex justify-center items-center w-[100%]" }
const _hoisted_2 = ["id"]
const _hoisted_3 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SectionHeader = _resolveComponent("SectionHeader")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_PrimaryButton = _resolveComponent("PrimaryButton")!
  const _component_Popper = _resolveComponent("Popper")!
  const _component_ArticleContainer = _resolveComponent("ArticleContainer")!
  const _component_TableOfContents = _resolveComponent("TableOfContents")!
  const _component_HowToQuote = _resolveComponent("HowToQuote")!
  const _component_RemoteContent = _resolveComponent("RemoteContent")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_SectionHeader, {
      title: _ctx.$t('commentary.introduction')
    }, null, 8, ["title"]),
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_PrimaryButton, { class: "text-black w-72" }, {
        default: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: { name: 'CommentaryOverview' },
            class: "grid place-content-center text-black h-full w-full"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('commentary.overview')), 1)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _createVNode(_component_Popper),
    _createVNode(_component_RemoteContent, { data: _ctx.intro }, {
      default: _withCtx(() => [
        (_ctx.isLoaded(_ctx.intro))
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              _createVNode(_component_ArticleContainer, { class: "my-3" }, {
                default: _withCtx(() => [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.intro.payload.title), 1),
                  _createElementVNode("h4", null, _toDisplayString(_ctx.intro.payload.author), 1),
                  _createElementVNode("h4", null, _toDisplayString(_ctx.intro.payload.subauthor), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_TableOfContents, {
                listing: _ctx.intro.payload.table_of_contents,
                class: "my-3"
              }, null, 8, ["listing"]),
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.intro.payload.sections, (section) => {
                return (_openBlock(), _createBlock(_component_ArticleContainer, {
                  key: section.title,
                  class: "my-3 showTable spaceHeaders spaceParagraphsSmall"
                }, {
                  default: _withCtx(() => [
                    _createElementVNode("h2", {
                      id: section.id
                    }, _toDisplayString(section.title), 9, _hoisted_2),
                    _createElementVNode("div", {
                      innerHTML: section.content,
                      class: "showLinks break-words"
                    }, null, 8, _hoisted_3)
                  ]),
                  _: 2
                }, 1024))
              }), 128)),
              _createVNode(_component_HowToQuote, {
                citation: _ctx.intro.payload.how_to_cite
              }, null, 8, ["citation"])
            ], 64))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }, 8, ["data"])
  ], 64))
}